/**
 * multiTidUtils.js contains common code to mixin to any component that takes a
 * "tickers" prop which is an array of tids (String tids?) and then does something
 * with that group of TIDs - i.e. WatchlistNews component, WatchlistFiling Component,
 * WatchlistTranscript Component
 */

// import { utcParse } from "d3-time-format" // isoParse

export default {
  props: {
    tickers: {
      type: Array,
      default: () => [],
    },
    wid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hourThreshold: 0.5,
    }
  },
  computed: {
    tids() {
      // just an array of tids
      return this.tickers.filter((f) => f.tid).map((t) => t.tid)
    },
    cids() {
      return this.tickers
        .filter((f) => f.cid)
        .reduce((acc, i) => {
          acc[i.cid] = i
          return acc
        }, {})
    },
    tickerObj() {
      return this.tickers.reduce((acc, ticker) => {
        if (ticker.tid) {
          acc[ticker.tid] = ticker
        }
        return acc
      }, {})
    },
    rkdids() {
      // just an array of the RKD objects
      const rkdIds = this.$store.state.watchlist.rkdids || {}
      return this.tids.reduce((acc, tid) => {
        if (rkdIds[tid]) {
          acc.push(rkdIds[tid])
        }
        return acc
      }, [])
    },
    prics() {
      // primaryRICs
      return this.rkdids.reduce((acc, map) => {
        if (map.PrimaryRIC) {
          acc[map.PrimaryRIC] = map
        }
        return acc
      }, {})
    },
    permids() {
      // primary OAPermID...
      return this.rkdids.reduce((acc, map) => {
        if (map.IssuerOAPermID) {
          acc[map.IssuerOAPermID] = map
        }
        return acc
      }, {})
    },
  },
  // watch: {
  //   tickers(newList, oldList) {
  //     console.log("tickers changed newList: ", newList)
  //     console.log("tickers changed oldList: ", oldList)
  //
  //   },
  // },
  // methods: {
  //   parseUtcTime() {
  //     return utcParse("%Y-%m-%dT%H:%M:%SZ")
  //   },
  // },
}
